.select-lang {
  width: 54px;
  height: 30px;
  position: relative;
  cursor: pointer;
  background: transparent;
  z-index: 101;
}

.flag_button {
  font-family: var(--Nunito_Sans);
  font-weight: 600;
  width: 54px;
  border: 1px solid #cdcdcd;
  outline: none;
  background: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  border-radius: 10px;
  background-color: #eeeeee;
  box-shadow: inset 0 0 10px #a8a8a8;
  transition: all 0.2s;
  color: #5a5a5a;
}

.flag_button:hover {
  cursor: pointer;
  box-shadow: inset 0 0 10px #8d8d8d;
}

ul.options {
  width: 54px;
  display: none;
  list-style: none;
  position: absolute;
  z-index: 99;
  background: transparent;
  padding-top: 2px;
}

ul.show {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4px;
}

ul.options li {
  padding: 4px 10px;
  display: flex;
  font-family: var(--Nunito_Sans);
  font-size: 12px;
  font-weight: 600;
  color: #5a5a5a;
  width: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #eeeeee;
  box-shadow: inset 0 0 10px #8d8d8d;
  transition: all 0.2s;
}

ul.options li:active,
ul.options li:focus,
ul.options li:hover,
ul.options li[aria-selected="true"] {
  cursor: pointer;
  color: var(--primary-color);
  box-shadow: inset 0 0 10px #8d8d8d;
}

/* @media screen and (max-width: 600px) {
  .select-lang {
    width: 10px;
    margin-left: 0;
    position: relative;
    cursor: pointer;
  }
  .select-lang button span {
    display: none;
  }
  .select-lang button {
    width: 10px;
  }
  button::after {
    content: none;
  }

  button.expanded::after {
    content: none;
  }
  ul.options {
    width: 50px;
    height: 110px;
    padding: 4px 0;
    position: absolute;
    top: 36px;
    padding-top: 10px;
  }
  ul.options li {
    height: 45px;
    width: 50px;
    display: flex;
    align-items: center;

    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 44px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #3f3e3e;
  }
  ul.options li span {
    display: none;
  }
} */

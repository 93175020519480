.price_list_container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.price_list_wrapper {
  width: 400px;
}
.price_loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30%;
}
.filter_room {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  margin: 40px 0;
}
.price_list_filter_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  margin-top: 20px;
}
.price_list_filter_options_items {
  display: flex;
  column-gap: 10px;
  width: 160px;
}
.price_list_filter_option_item {
  font-family: var(--Nunito_Sans);
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-color);
  cursor: pointer;
  width: 40px;
  text-align: center;
}
.price_list_filter_options_info {
  font-family: var(--Nunito_Sans);
  font-weight: 600;
  font-size: 16px;
  color: var(--third-color);
}
.price_list_filter_option_indicater {
  height: 3px;
  background-color: #ebf0f3;
}
.price_list_filter_option_indicater span {
  height: 3px;
  width: 40px;
  display: block;
  position: relative;
  left: 0;
  background-color: orange;
  transition: all 0.3s;
}

.price_list_items {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-top: 20px;
  overflow-y: scroll;
  height: 465px;
  padding: 0 10px;
}
.price_list_item {
  font-family: var(--Nunito_Sans);
  background-color: #ebf0f3;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px 12px;
  row-gap: 2px;
}
.price_list_item:nth-child(odd) {
  background-color: #d3e2eb;
}
.price_list_item_dates {
  font-size: 16px;
  font-weight: 600;
}
.price_list_item_info {
  font-size: 14px;
  color: rgb(167, 167, 167);
  grid-column: 1;
}
.price_list_item_price {
  font-size: 16px;
  font-weight: 600;
  grid-column: 2;
  grid-row: 1 / span 2;
  align-self: center;
  text-align: right;
}
.filter_btn {
  font-family: var(--Nunito_Sans);
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  padding: 10px 40px;
  border-radius: 50px;
  border: 1px solid rgba(34, 100, 80, 0.61);
  color: rgba(34, 100, 80, 0.91);
  background-color: #fff;
  cursor: pointer;
}
.filter_btn:hover {
  color: #fff;
  background-color: rgba(34, 100, 80, 0.61);
}

.active_filter_btn {
  color: #fff;
  background-color: rgba(34, 100, 80, 0.61);
  /* transition: all 0.2s; */
}
.active_filter_btn:hover {
  color: #fff;
  background-color: rgba(34, 100, 80, 0.61);
}
@media screen and (max-width: 767px) {
  .price_list_container {
    padding: 0 30px;
  }
  .price_list_wrapper {
    width: 100%;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  background: var(--secondary-color);
}

:root {
  --primary-color: #000;
  --secondary-color: #628a76;
  --third-color: #16c0a4;
  --white-color: #fff;
  --Nunito_Sans: "Nunito Sans", sans-serif;
  --rubik: "Rubik", sans-serif;
  --bacasime: "Bacasime Antique", serif;
  --cavaet: "Caveat", cursive;
  --dancing: "Dancing Script", cursive;
  --color_danger: rgb(230, 34, 93);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  height: auto;
  width: 100%;
  overflow-x: clip;
}
a {
  text-decoration: none;
}
button {
  border: none;
}

ul {
  list-style: none;
}
.p-15 {
  padding: 150px 15px;
}
.p-30 {
  padding: 120px 30px;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 500ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 500ms;
}

.section_wrapper {
  margin: 0 auto;
  padding: 120px 150px 100px;
  position: relative;
}

.section_title {
  font-family: var(--Nunito_Sans);
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .section_wrapper {
    margin: 0 auto;
    padding: 80px 30px 70px;
    position: relative;
  }

  .section_title {
    font-size: 26px;
    margin-bottom: 0;
  }
}

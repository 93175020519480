.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 100%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

}
.side-drawer_wrapper {
  /* width: 550px; */
  position: relative;
  margin: 0 auto;
}
.close_price {
  position: absolute;
  top: 40px;
  font-size: 24px;
  right: 20px;
  cursor: pointer;
}
@media screen and (max-width: 767px){
  .side-drawer_wrapper {
    width: 100%;
    position: relative;
    margin: 0 auto;
  }
  .close_price {
    top: 10px;
    right: 20px;
  }
}
.socialbar-container {
  width: 70px;
  height: 100vh;
  position: absolute;
  left: 10px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialbar_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.social-item {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
}
.socialbar_wrapper span {
  display: block;
  width: 3px;
  height: 50px;
  background-color: var(--secondary-color);
  margin: 6px 0;
}
.socialbar_wrapper span:nth-child(3),
.socialbar_wrapper span:nth-child(5),
.socialbar_wrapper span:nth-child(7) {
  display: block;
  width: 3px;
  height: 20px;
  background-color: var(--secondary-color);
}
.social-item svg {
  color: var(--white-color);
  font-size: 24px;
}
.social-item:hover {
  box-shadow: 0 0 10px var(--secondary-color);
}
